import React, { useState, useEffect, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import FilterModal from "pages/partnersList/dialog/partnerFiltersDialog";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import FormInput from "../../../components/ui/FormContent/formInput";
import FormSelect from "../../../components/ui/FormContent/formSelect";
import { ArrowBack } from "../icons/ArrowBack";
import { TradeVariantForm } from "./TradeVariantForm";
import {
  filterCitiesBySelectedStates,
  filterZipCodesBySelectedCities,
} from "components/util/filterGeolocationUtils";
import {
  addPartnerTradeVariant,
  createTrade,
  getTradesList,
  updatePartnerTrade,
  updatePartnerTradeVariant,
} from "../../../services/partners/tradesService";
import { useFilterDialog } from "../context/FilterDialogContext";
import { useGetUserId } from "../context/UserIdContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Joi from "joi-browser";
import useStyles from "../styles";
import * as classnames from "classnames";

const validationSchema = Joi.object({
  states: Joi.array().min(1).required().label("States"),
  cities: Joi.array().min(1).required().label("Cities"),
  tradeId: Joi.number()
    .required()
    .label("TradeId")
    .error(() => {
      return {
        message: "Trade is required.",
      };
    }),
});

const joiErrorToMessage = (error) => {
  const errorMessages = {};
  error.details.forEach((detail) => {
    errorMessages[detail.path[0]] = detail.message;
  });
  return errorMessages;
};

export function AddTradeDrawer({
  drawerState,
  toggleDrawer,
  handleToggleDrawer,
  tradeToEdit,
  isEditingTrade,
  setIsEditingTrade,
  isVariant,
  setIsVariant,
  workItemId,
  addingVariant,
  setAddingVariant,
  partnerTradeId,
  statesData,
  citiesData,
  zipCodesData,
}) {
  const classes = useStyles();
  const [data, setData] = useState({
    // Initialize state based on whether we're editing or creating a new trade
    trade_id: "",
    name: "",
    fixed_rate: 0,
    hourly_rate: 0,
    sqf_rate: 0,
    zip_codes: "",
    cities: "",
    states: "",
    // Add more fields if necessary
  });

  const [variantForms, setVariantForms] = useState([]);
  const [errors, setErrors] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [, setFilterCities] = useState([]);
  const [, setFilterStates] = useState([]);
  const [, setFilterZipCodes] = useState([]);
  const [, setCitiesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [, setZipCodesList] = useState([]);
  const [variantErrors, setVariantErrors] = useState([]);
  const [variantHasError, setVariantHasError] = useState(false);
  const [failedSubmitVariant, setFailedSubmitVariant] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTradeId, setSelectedTradeId] = useState("");
  const [selectedItems, setSelectedItems] = useState({
    states: [],
    cities: [],
    zipCodes: [],
  });
  const [areThereErrors, setAreThereErrors] = useState(true);
  const [areThereVariantErrors, setAreThereVariantErrors] = useState(false);
  const { handleDialogueOpen, setIsEditingVariant, variantsLocations } =
    useFilterDialog();
  const { userId } = useGetUserId();

  const dataLists = {
    states: statesData,
    cities: citiesData,
    zipCodes: zipCodesData,
  };

  const tradesQuery = useQuery("partnerTradesList", async () => {
    const { data } = await getTradesList();
    return data.data.trades;
  });

  const handleAddVariantForm = () => {
    setVariantForms((prevForms) => [
      ...prevForms,
      {
        variant_name: "",
        hourly_rate: "",
        fixed_rate: "",
        sqf_rate: "",
        states: "",
        cities: "",
        zip_codes: "",
      },
    ]);
  };

  const deleteVariantCB = (index) => {
    setVariantForms((prev) => {
      const updatedForms = [...prev];
      updatedForms.splice(index, 1);
      return updatedForms;
    });
  };

  useEffect(() => {
    if (selectedItems.states.length > 0) {
      const assignedCitiesToSelectedStates = filterCitiesBySelectedStates(
        selectedItems.cities,
        selectedItems.states
      );

      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        cities: assignedCitiesToSelectedStates,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.states]);
  //verify if object has trade_id states and cities. Update areThereErrorsAccordingly
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      if (
        data.trade_id &&
        selectedItems.states.length > 0 &&
        selectedItems.cities.length > 0
      ) {
        setAreThereErrors(false);
      } else {
        setAreThereErrors(true);
      }
    }
  }, [data, selectedItems.states, selectedItems.cities]);
  //check the same for variants if there are any and update areThereErrorsAccordingly
  useEffect(() => {
    if (variantForms.length > 0) {
      const hasError = variantForms.some((form, index) => {
        return (
          !form.variant_name ||
          !variantsLocations[index].states ||
          !variantsLocations[index].cities
        );
      });
      setAreThereVariantErrors(hasError);
    } else {
      setAreThereVariantErrors(false);
    }
  }, [variantForms]);
  useEffect(() => {
    if (selectedItems.cities.length > 0) {
      const assignedZipCodesToSelectedCities = filterZipCodesBySelectedCities(
        selectedItems.zipCodes,
        selectedItems.cities
      );

      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        zipCodes: assignedZipCodesToSelectedCities,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.cities]);

  const handleOpenModal = (type) => {
    setFilterType(type);

    const selectedItemsForType = dataLists[type].filter((item) =>
      selectedItems[type]?.some((selected) => selected.value === item.value)
    );
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [type]: selectedItemsForType,
    }));

    setModalOpen(true);
  };

  const handleModalChange = (selected) => {
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [filterType]: selected,
    }));

    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (selected.length > 0) {
        delete updatedErrors[filterType];
      } else {
        updatedErrors[filterType] = `${
          filterType.charAt(0).toUpperCase() + filterType.slice(1)
        } is required`;
      }
      return updatedErrors;
    });

    setModalOpen(false);
  };
  const handleCloseModal = () => setModalOpen(false);

  const renderSelectedValues = (items) => {
    return items.map((item) => item.label).join(", ");
  };

  // Effect to set data when tradeToEdit changes
  useEffect(() => {
    if (isEditingTrade && tradeToEdit) {
      const mappedStates = tradeToEdit.states.map((state) => ({
        value: state.abbreviation,
        label: state.name,
      }));

      const mappedCities = tradeToEdit.cities.map((city) => ({
        value: city.id,
        label: city.city_name,
        state_abbreviation: city.state_abbreviation,
      }));

      const mappedZipCodes = tradeToEdit.zip_codes.map((zip) => {
        const city = mappedCities.find(
          (city) => parseInt(city.value) === parseInt(zip.city_id)
        );
        const stateAbbreviation = city ? city.state_abbreviation : "";
        return {
          value: zip.id,
          label: zip.zip,
          city: city ? city.label : "",
          state_abbreviation: stateAbbreviation,
        };
      });

      setSelectedItems((prevState) => ({
        ...prevState,
        states: mappedStates,
        cities: mappedCities,
        zipCodes: mappedZipCodes,
      }));

      // Logic to set additional data based on isVariant
      if (isVariant) {
        setData({
          partner_trade_variation_id: tradeToEdit.id || "",
          fixed_rate: tradeToEdit.fixed_rate || 0,
          hourly_rate: tradeToEdit.hourly_rate || 0,
          sqf_rate: tradeToEdit.sqf_rate || 0,
          name: tradeToEdit.name || "",
        });
      } else {
        setData({
          partner_trade_id: tradeToEdit.id || "",
          fixed_rate: tradeToEdit.fixed_rate || 0,
          hourly_rate: tradeToEdit.hourly_rate || 0,
          sqf_rate: tradeToEdit.sqf_rate || 0,
        });
      }
    } else {
      setData({
        trade_id: "0",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
      });
    }
  }, [tradeToEdit, isEditingTrade, isVariant]);

  const queryClient = useQueryClient();
  function onSubmitEditing() {
    setIsLoading(true);

    let states_added = [];
    let cities_added = [];
    let zip_codes_added = [];
    let states_removed = [];
    let cities_removed = [];
    let zip_codes_removed = [];

    // Filtering cities
    if (tradeToEdit.cities.length > 0) {
      tradeToEdit.cities.forEach((city) => {
        if (!selectedItems.cities.map((c) => c.value).includes(city.id)) {
          cities_removed.push(city.id);
        }
      });
      selectedItems.cities.forEach((city) => {
        if (!tradeToEdit.cities.some((c) => c.id === city.value)) {
          cities_added.push(city.value);
        }
      });
    } else {
      cities_added = selectedItems.cities.map((city) => city.value);
    }

    // Filtering states
    if (tradeToEdit.states.length > 0) {
      tradeToEdit.states.forEach((state) => {
        if (
          !selectedItems.states.map((s) => s.value).includes(state.abbreviation)
        ) {
          states_removed.push(state.abbreviation);
        }
      });
      selectedItems.states.forEach((state) => {
        if (!tradeToEdit.states.some((s) => s.abbreviation === state.value)) {
          states_added.push(state.value);
        }
      });
    } else {
      states_added = selectedItems.states.map((state) => state.value);
    }

    // Filtering Zip Codes
    if (tradeToEdit.zip_codes.length > 0) {
      tradeToEdit.zip_codes.forEach((zip) => {
        if (!selectedItems.zipCodes.map((z) => z.value).includes(zip.id)) {
          zip_codes_removed.push(zip.id);
        }
      });
      selectedItems.zipCodes.forEach((zip) => {
        if (!tradeToEdit.zip_codes.some((z) => z.id === zip.value)) {
          zip_codes_added.push(zip.value);
        }
      });
    } else {
      zip_codes_added = selectedItems.zipCodes.map((zip) => zip.value);
    }

    if (isVariant) {
      const object = {
        userId: userId,
        partner_id: tradeToEdit.partner_trade_id,
        data: {
          ...data,
          states_added: states_added,
          cities_added: cities_added,
          zip_codes_added: zip_codes_added,
          states_deleted: states_removed,
          cities_deleted: cities_removed,
          zip_codes_deleted: zip_codes_removed,
        },
      };
      editVariantMutation.mutate(object);
    } else {
      const object = {
        data: {
          ...data,
          states_added: states_added,
          cities_added: cities_added,
          zip_codes_added: zip_codes_added,
          states_deleted: states_removed,
          cities_deleted: cities_removed,
          zip_codes_deleted: zip_codes_removed,
        },
      };

      editMutation.mutate({
        partner_id: userId,
        data: object,
      });
    }
  }

  const mutation = useMutation(createTrade, {
    onSuccess: () => {
      queryClient.invalidateQueries("trades");
      setData({
        trade_id: "",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
      });
      setFilterStates([]);
      setFilterCities([]);
      setFilterZipCodes([]);
      setCitiesList([]);
      setStatesList([]);
      handleToggleDrawer("right", false);
      setIsLoading(false);
      setVariantForms([]);
      setErrors([]);
      setZipCodesList([]);
      setValidationErrors({});
      setSelectedItems({
        states: [],
        cities: [],
        zipCodes: [],
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const addVariantMutation = useMutation(addPartnerTradeVariant, {
    onSuccess: () => {
      queryClient.invalidateQueries("tradeVariant");
      setData({
        trade_id: "",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
      });
      handleToggleDrawer("right", false);
      setIsLoading(false);
      setAddingVariant(false);
      setFilterStates([]);
      setFilterCities([]);
      setFilterZipCodes([]);
      setZipCodesList([]);
      setCitiesList([]);
      setStatesList([]);
      setVariantForms([]);
      setValidationErrors({});
      setSelectedItems({
        states: [],
        cities: [],
        zipCodes: [],
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const editMutation = useMutation(updatePartnerTrade, {
    onSuccess: () => {
      queryClient.invalidateQueries("trades");
      setData({
        trade_id: "",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
      });
      handleToggleDrawer("right", false);
      setIsLoading(false);
      setIsEditingTrade(false);
      setCitiesList([]);
      setStatesList([]);
      setFilterZipCodes([]);
      setFilterCities([]);
      setZipCodesList([]);
      setFilterStates([]);
      setValidationErrors({});
      setSelectedItems({
        states: [],
        cities: [],
        zipCodes: [],
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const editVariantMutation = useMutation(updatePartnerTradeVariant, {
    onSuccess: () => {
      queryClient.invalidateQueries("tradeVariant");
      setData({
        trade_id: "",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
        name: "",
        zip_codes: "",
      });
      handleToggleDrawer("right", false);
      setIsLoading(false);
      setIsVariant(false);
      setCitiesList([]);
      setStatesList([]);
      setFilterZipCodes([]);
      setFilterCities([]);
      setZipCodesList([]);
      setFilterZipCodes([]);
      setZipCodesList([]);
      setCitiesList([]);
      setFilterStates([]);
      setErrors([]);
      setValidationErrors({});
      setSelectedItems({
        states: [],
        cities: [],
        zipCodes: [],
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const handleBlur = (event) => {
    setData({ ...data, [event.name]: event.value });

    if (event.name === "trade_id") {
      setSelectedTradeId(event.value);
    }
  };

  function resetForm() {
    setData({
      trade_id: "",
      fixed_rate: 0,
      hourly_rate: 0,
      sqf_rate: 0,
      zip_codes: "",
      cities: "",
      states: "",
    });
    setCitiesList([]);
    setStatesList([]);
    setZipCodesList([]);
    setFilterStates([]);
    setErrors([]);
    setFilterCities([]);
    setFilterZipCodes([]);
    setIsLoading(false);
    //setAddingVariant(false);
    setVariantForms([]);
    setValidationErrors({});
    setSelectedItems({
      states: [],
      cities: [],
      zipCodes: [],
    });
  }

  const handleVariantBlur = (e, index, states, zipcodes, cities, location) => {
    setVariantForms((prevForms) => {
      const updatedForms = [...prevForms];
      if (location) {
        updatedForms[index] = {
          ...updatedForms[index],
          [e.name]: e.value, // Ensure to use e.target.name
          zip_codes: zipcodes,
          cities: cities,
          states: states,
        };
      } else {
        updatedForms[index] = {
          ...updatedForms[index],
          [e.name]: e.value, // Ensure to use e.target.name
        };
      }
      return updatedForms;
    });
  };
  const validateSelectedItems = (selectedItems) => {
    const { error } = validationSchema.validate(
      {
        states: selectedItems.states,
        cities: selectedItems.cities,
        tradeId: selectedTradeId,
      },
      { abortEarly: false }
    );

    if (error) {
      setValidationErrors(joiErrorToMessage(error));
      return false;
    }

    setValidationErrors({});
    return true;
  };

  const onSubmit = async () => {
    let hasError = false;
    setFailedSubmitVariant(false);

    if (!validateSelectedItems(selectedItems)) {
      return;
    }

    const object = {
      ...data,
      states: selectedItems.states.map((item) => item.value),
      cities: selectedItems.cities.map((item) => item.value),
      zip_codes: selectedItems.zipCodes?.map((item) => item.value) || [],
      variations: !addingVariant
        ? variantForms.map((form, index) => ({
            name: form.variant_name,
            hourly_rate: form.hourly_rate,
            fixed_rate: form.fixed_rate,
            sqf_rate: form.sqf_rate,
            states: variantsLocations[index].states,
            cities: variantsLocations[index].cities,
            zip_codes: variantsLocations[index].zip_codes || [],
          }))
        : undefined, // Only include variations if not adding a new variant
    };

    // Check if at least one of the rates is filled
    const isRateValid =
      !!object.fixed_rate || !!object.hourly_rate || !!object.sqf_rate;

    // Clear previous errors for rates
    const rateFields = ["fixed_rate", "hourly_rate", "sqf_rate"];
    rateFields.forEach((field) => {
      const index = errors.findIndex((error) => error.key === field);
      if (index !== -1) {
        setErrors((prevErrors) =>
          prevErrors.filter((error) => error.key !== field)
        );
      }
    });

    // Add errors if none of the rates are filled
    // if (!isRateValid) {
    //   rateFields.forEach((field) => {
    //     const dummyError = {
    //       key: field,
    //       message: "At least one rate is required",
    //     };
    //     setErrors((prevErrors) => [...prevErrors, dummyError]);
    //   });
    //   hasError = true;
    // }

    // const requiredFields = ["trade_id", "zip_codes", "cities", "states"];
    //variants dont need trade id to be filled up since it already comes with a value
    const requiredFields = addingVariant
      ? ["zip_codes", "cities", "states"]
      : ["trade_id", "cities", "states"];
    requiredFields.forEach((field) => {
      if (!object[field] || object[field].length === 0 || object[field] === 0) {
        const dummyError = {
          key: field,
          message: "This field is required",
        };
        setErrors((prevErrors) => [...prevErrors, dummyError]);
        hasError = true;
      }
    });

    // If there are validation errors or variant errors, stop submission
    if (hasError || variantHasError) {
      setFailedSubmitVariant(true);
      return;
    }

    if (addingVariant) {
      const variantObject = {
        partner_trade_id: partnerTradeId,
        partner_id: userId,
        data: object, // Include the prepared object
      };
      await addVariantMutation.mutate(variantObject);
    } else {
      await mutation.mutate({
        data: object,
        partner_id: userId,
      });
    }
  };
  const handleCloseDrawer = () => {
    handleToggleDrawer("right", false);
    setIsEditingTrade(false);
    setCitiesList([]);
    setStatesList([]);
    setZipCodesList([]);
    setFilterStates([]);
    setErrors([]);
    setValidationErrors({});
    resetForm();
    setVariantForms([]);
    setVariantErrors([]);
    setVariantHasError(false);
    setSelectedItems({
      states: [],
      cities: [],
      zipCodes: [],
    });
  };

  return (
    <Drawer
      anchor={"right"}
      open={drawerState["right"]}
      onClose={handleCloseDrawer}
    >
      <Box className={classes.drawerOuterContainer}>
        <Box className={classes.drawerContainer}>
          <Box>
            <Box className={classes.drawerTitleContainer}>
              <Box
                onClick={toggleDrawer("right", false)}
                className={classes.drawerCloseIcon}
              >
                <CloseIcon
                  style={{ fontSize: 16 }}
                  className={classes.drawerCloseIcon}
                  onClick={handleCloseDrawer}
                />
              </Box>
              <Typography className={classes.drawerTitle}>
                {isEditingTrade ? "Edit Trade" : "Add Trade"}
              </Typography>
              <Typography className={classes.drawerInfoText}>
                {isEditingTrade
                  ? "Edit the details of this trade."
                  : "Provide details for the new trade."}
              </Typography>
            </Box>
            {(mutation.isLoading ||
              editMutation.isLoading ||
              editVariantMutation.isLoading ||
              addVariantMutation.isLoading) && <LoadingIndicator />}
            {!mutation.isLoading &&
              !editMutation.isLoading &&
              !editVariantMutation.isLoading &&
              !addVariantMutation.isLoading && (
                <Box className={classes.tradingDrawerFormContainer}>
                  <Box className={classes.drawerFieldsFormContainer}>
                    {!isVariant && (
                      <Box onClick={() => {}}>
                        <FormSelect
                          name="trade_id"
                          error={errors}
                          redirectIcon={true}
                          readonly={false} // Set to false if you want to allow editing
                          label="Trades Offered *"
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={
                            tradesQuery.data
                              ? tradesQuery.data
                                  .map((trade) => ({
                                    value: trade.id,
                                    label: trade.trade_name,
                                  }))
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  )
                              : []
                          }
                          disabled={addingVariant}
                          value={
                            addingVariant
                              ? workItemId
                              : tradeToEdit?.trade_id
                              ? tradeToEdit?.trade_id
                              : "0"
                          }
                          styleOverride={{ minWidth: "321px" }}
                          handleBlur={handleBlur}
                          onChange={(e) => setSelectedTradeId(e.target.value)}
                        />
                        {!!validationErrors.tradeId && (
                          <FormHelperText className={classes.formHelperText}>
                            {validationErrors.tradeId}
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                    {(addingVariant || isVariant) && (
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="name"
                        label="Variant Name *"
                        error={errors}
                        value={data.name}
                        readOnly={false}
                        styleOverride={{ minWidth: "114px" }}
                        handleBlur={handleBlur}
                      />
                    )}

                    <Typography className={classes.standardPriceText}>
                      STANDARD PRICE
                    </Typography>
                    <Box className={classes.ratesFormContainer}>
                      <FormInput
                        gridSizes={[{ size: "md", val: 6 }]}
                        name="hourly_rate"
                        type="number"
                        label="Hourly Rate"
                        value={data.hourly_rate}
                        readOnly={false}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        error={errors}
                        styleOverride={{ minWidth: "114px" }}
                        handleBlur={handleBlur}
                      />
                      <FormInput
                        gridSizes={[{ size: "md", val: 6 }]}
                        name="fixed_rate"
                        label="Fixed Rate"
                        type="number"
                        value={data.fixed_rate}
                        readOnly={false}
                        error={errors}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        styleOverride={{ minWidth: "114px" }}
                        handleBlur={handleBlur}
                      />
                    </Box>
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="sqf_rate"
                      label="Sq Footage Rate"
                      value={data.sqf_rate}
                      type="number"
                      readOnly={false}
                      error={errors}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      styleOverride={{ minWidth: "114px" }}
                      handleBlur={handleBlur}
                    />

                    <>
                      {statesData.length === 0 ? (
                        <LinearProgress color="secondary" />
                      ) : (
                        <Box>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <FormLabel className={classes.filterLabel}>
                              States Serviced*
                            </FormLabel>
                            <Box
                              onClick={() => {
                                handleOpenModal("states");
                                setIsEditingVariant(true);
                                setFilterType("states");
                              }}
                              className={classes.box}
                            >
                              <TextField
                                required
                                fullWidth
                                id="states"
                                name="states"
                                variant="standard"
                                placeholder="Select state"
                                value={renderSelectedValues(
                                  selectedItems.states
                                )}
                                InputProps={{
                                  disableUnderline: true,
                                  readOnly: true,
                                  endAdornment: (
                                    <IconButton>
                                      <ArrowRightIcon />
                                    </IconButton>
                                  ),
                                }}
                                className={classnames(
                                  classes.inputFieldFilters,
                                  classes.readOnlyInput
                                )}
                              />
                            </Box>
                            {!!validationErrors.states && (
                              <FormHelperText
                                className={classes.formHelperText}
                              >
                                {validationErrors.states}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      )}

                      {citiesData.length === 0 ? (
                        <LinearProgress color="secondary" />
                      ) : (
                        <Box>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <FormLabel
                              className={
                                selectedItems.states.length > 0
                                  ? classes.filterLabel
                                  : classes.inputLabel
                              }
                            >
                              Cities Serviced*
                            </FormLabel>
                            <Box
                              onClick={() => {
                                if (selectedItems.states.length > 0) {
                                  handleOpenModal("cities");
                                  setIsEditingVariant(true);
                                }
                              }}
                              className={classes.box}
                            >
                              <TextField
                                required
                                fullWidth
                                id="cities"
                                name="cities"
                                variant="standard"
                                placeholder="Select Cities "
                                value={renderSelectedValues(
                                  selectedItems.cities
                                )}
                                InputProps={{
                                  disableUnderline: true,
                                  readOnly: true,
                                  endAdornment: selectedItems.states.length >
                                    0 && (
                                    <IconButton>
                                      <ArrowRightIcon />
                                    </IconButton>
                                  ),
                                }}
                                className={classnames(
                                  classes.inputFieldFilters,
                                  {
                                    [classes.readOnlyInput]:
                                      selectedItems.states.length <= 0,
                                  }
                                )}
                              />
                            </Box>
                            {!!validationErrors.cities && (
                              <FormHelperText
                                className={classes.formHelperText}
                              >
                                {validationErrors.cities}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      )}

                      {zipCodesData.length === 0 ? (
                        <LinearProgress color="secondary" />
                      ) : (
                        <Box>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <FormLabel
                              className={
                                selectedItems.cities.length > 0
                                  ? classes.filterLabel
                                  : classes.inputLabel
                              }
                            >
                              Zip Codes
                            </FormLabel>

                            <Box
                              onClick={() => {
                                if (selectedItems.cities.length > 0) {
                                  setIsEditingVariant(true);
                                  setFilterType("zip_codes");
                                  handleOpenModal("zipCodes");
                                }
                              }}
                              className={classes.box}
                            >
                              <TextField
                                fullWidth
                                id="zipCodes"
                                name="zipCodes"
                                variant="standard"
                                placeholder="Select Zip Codes"
                                value={renderSelectedValues(
                                  selectedItems.zipCodes
                                )}
                                InputProps={{
                                  disableUnderline: true,
                                  readOnly: true,
                                  endAdornment: selectedItems.cities.length >
                                    0 && (
                                    <IconButton>
                                      <ArrowRightIcon />
                                    </IconButton>
                                  ),
                                }}
                                className={classnames(
                                  classes.inputFieldFilters,
                                  {
                                    [classes.readOnlyInput]:
                                      selectedItems.cities.length <= 0 &&
                                      selectedItems.zipCodes.length === 0,
                                  }
                                )}
                              />
                            </Box>
                          </FormControl>
                        </Box>
                      )}
                    </>
                  </Box>

                  {!isEditingTrade && (
                    <Fragment>
                      {variantForms.map((form, index) => (
                        <Fragment key={index}>
                          <TradeVariantForm
                            data={form}
                            handleBlur={handleVariantBlur}
                            index={index}
                            setVariantErrors={setVariantErrors}
                            variantErrors={variantErrors}
                            setVariantHasError={setVariantHasError}
                            openDialogue={handleDialogueOpen}
                            failedSubmitVariant={failedSubmitVariant}
                            deleteVariant={deleteVariantCB}
                            citiesListData={citiesData}
                            statesListData={statesData}
                            zipCodesListData={zipCodesData}
                          />
                        </Fragment>
                      ))}
                      {!addingVariant && (
                        <Button
                          className={classes.addPricingVariationButton}
                          onClick={handleAddVariantForm}
                        >
                          Add Pricing Variation
                        </Button>
                      )}
                    </Fragment>
                  )}
                </Box>
              )}
          </Box>
        </Box>
        <Box className={classes.drawerFieldsFormContainer}></Box>
        <Box className={classes.drawerTradingButtonsContainer}>
          <Button
            onClick={handleCloseDrawer}
            startIcon={<ArrowBack />}
            className={classes.goBackButton}
            disableRipple={true}
          >
            Go Back
          </Button>
          <Button
            disabled={isLoading || areThereErrors || areThereVariantErrors}
            onClick={isEditingTrade ? onSubmitEditing : onSubmit}
            className={classes.saveChangesButton}
          >
            {isEditingTrade ? "Save Changes" : "Save Trade"}
          </Button>
        </Box>
      </Box>
      <FilterModal
        isOpen={modalOpen}
        onCloseModal={handleCloseModal}
        availableStates={statesList}
        filterType={filterType}
        optionsList={dataLists[filterType] || []}
        currentSelections={selectedItems}
        onFilterChange={handleModalChange}
      />
    </Drawer>
  );
}
