// React imports
import React, { Fragment, useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import { DeleteBin } from "pages/partnerPage/icons/DeleteBin";
import ThreeDots from "../icons/ThreeDots";
import ChipList from "../../partnersList/chipList";
import { AddTradeDrawer } from "./AddTradeDrawer";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import { useGetUserId } from "../context/UserIdContext";
import {
  deletePartnerTradeVariant,
  getPartnerTradesVariants,
} from "../../../services/partners/tradesService";
import { getStates, getCities, getZipCodes } from "services/partnerService";
import {
  mapData,
  fetchAndSetData,
} from "components/util/filterGeolocationUtils";
import useStyles from "../styles";

export function TradesVariants({ tradeId }) {
  const classes = useStyles();
  const { userId } = useGetUserId();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [drawerState, setDrawerState] = useState({ right: false });
  const [tradeToEdit, setTradeToEdit] = useState(null);
  const [isVariant, setIsVariant] = useState(false);
  const [isEditingTrade, setIsEditingTrade] = useState(false);
  const [statesListData, setStatesListData] = useState([]);
  const [citiesListData, setCitiesListData] = useState([]);
  const [zipCodesListData, setZipCodesListData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const { data, error, isLoading } = useQuery(
    ["tradeVariant", tradeId],
    async () => {
      const { data } = await getPartnerTradesVariants(
        {
          tradeId,
          partner_id: userId,
        },
        {
          enabled: !!userId,
        }
      );
      return data.data.partner_trade_variations;
    }
  );

  const fetchData = async () => {
    await fetchAndSetData(
      getStates,
      setStatesListData,
      "states",
      "abbreviation",
      "name",
      {},
      mapData,
      setIsLoadingData
    );
    await fetchAndSetData(
      getCities,
      setCitiesListData,
      "cities",
      "id",
      "city_name",
      { state_abbreviation: "state_abbreviation" },
      mapData,
      setIsLoadingData
    );
    await fetchAndSetData(
      getZipCodes,
      setZipCodesListData,
      "zip_codes",
      "id",
      "zip",
      { city: "city.city_name", state_abbreviation: "city.state_abbreviation" },
      mapData,
      setIsLoadingData
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event, index) => {
    setAnchorEl({ element: event.currentTarget, index });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteTradeVariant = (partner_trade_id, id) => {
    setLoadingState(true);
    deleteTrade.mutate({ partner_trade_id, id, userId });
  };

  const handleEditTradeVariant = (trade, tradeId) => {
    setTradeToEdit({ ...trade, tradeId });
    setIsEditingTrade(true);
    setIsVariant(true);
    handleToggleDrawer("right", true);
    handleClose();
  };

  const handleToggleDrawer = (anchor, open) => {
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const deleteTrade = useMutation(deletePartnerTradeVariant, {
    onSuccess: () => {
      queryClient.invalidateQueries("tradeVariant");
      setLoadingState(false);
    },
    onError: () => {
      setLoadingState(false);
    },
  });

  const menuOptions = [
    {
      label: "Edit",
      icon: <EditIcon fontSize="inherit" />,
      action: (trade) => handleEditTradeVariant(trade, tradeId),
      disabled: loadingState,
    },
    {
      label: "Delete",
      icon: <DeleteBin fontSize="inherit" />,
      action: (trade) => handleDeleteTradeVariant(trade.id, tradeId),
      disabled: loadingState,
    },
  ];

  return (
    <Fragment>
      {(isLoading || isLoadingData) && <LoadingIndicator />}
      {!isLoading && !isLoadingData && (
        <Fragment>
          {data?.map((trade, tradeIndex) => (
            <Fragment key={tradeIndex}>
              <Box className={classes.accordionVariantStandard}>
                {trade?.states?.length === 1 ? (
                  <Box className={classes.accordionCityTitleContainer}>
                    <Typography className={classes.accordionDetailsSubtitle}>
                      {trade?.name}
                    </Typography>

                    <Box className={classes.tradesMultipleStatesContainer}>
                      <ChipList
                        isArray={true}
                        items={trade?.states}
                        maxItems={3}
                        nameProp={"name"}
                      />
                      <Box
                        onClick={(event) => handleClick(event, tradeIndex)}
                        disableRipple={true}
                        className={classes.threeDotButtonAccordion}
                      >
                        <ThreeDots />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.accordionCityTitleContainer}>
                    <Box className={classes.tradesOneCityContainer}>
                      <Typography className={classes.accordionDetailsSubtitle}>
                        {trade?.name}
                      </Typography>
                      <ChipList
                        isArray={true}
                        items={trade?.states}
                        maxItems={3}
                        nameProp={"name"}
                      />
                    </Box>
                    <IconButton
                      onClick={(event) => handleClick(event, tradeIndex)}
                      disableRipple={true}
                      className={classes.threeDotButtonAccordion}
                    >
                      <ThreeDots />
                    </IconButton>
                  </Box>
                )}
                <Box className={classes.ratesContainer}>
                  <Typography className={classes.ratesText}>
                    Hourly Rate:{" "}
                    <span className={classes.spanRatesText}>
                      {trade?.hourly_rate}
                    </span>
                  </Typography>
                  {trade?.fixed_rate > 0 && (
                    <>
                      <Box className={classes.verticalSeparatorRates} />
                      <Typography className={classes.ratesText}>
                        Fixed Rate:{" "}
                        <span className={classes.spanRatesText}>
                          ${trade?.fixed_rate}
                        </span>
                      </Typography>
                    </>
                  )}
                  {trade?.sqf_rate > 0 && (
                    <>
                      <Box className={classes.verticalSeparatorRates} />
                      <Typography className={classes.ratesText}>
                        Sq Footage Rate:{" "}
                        <span className={classes.spanRatesText}>
                          {trade?.sqf_rate}
                        </span>
                      </Typography>
                    </>
                  )}
                </Box>
                {trade.cities.length > 1 ? (
                  <Box className={classes.infoChipsContainer}>
                    <ChipList
                      isArray={true}
                      items={trade?.cities}
                      maxItems={3}
                      nameProp={"city_name"}
                    />
                  </Box>
                ) : (
                  trade?.cities[0] && (
                    <Box className={classes.infoChipsContainer}>
                      <Chip
                        label={trade?.cities[0]?.city_name}
                        className={classes.infoChip}
                      />
                    </Box>
                  )
                )}
              </Box>

              <Menu
                anchorEl={anchorEl?.element}
                keepMounted
                open={Boolean(anchorEl && anchorEl.index === tradeIndex)}
                onClose={handleClose}
              >
                {menuOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    disabled={option.disabled}
                    onClick={() => option.action(trade)}
                  >
                    <Box className={classes.menuIcon}>{option.icon}</Box>
                    <Typography variant="caption" className={classes.menuText}>
                      {option.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Fragment>
          ))}
        </Fragment>
      )}

      <AddTradeDrawer
        drawerState={drawerState}
        toggleDrawer={() => toggleDrawer("right", false)}
        handleToggleDrawer={handleToggleDrawer}
        isEditingTrade={isEditingTrade}
        setIsEditingTrade={setIsEditingTrade}
        tradeToEdit={tradeToEdit}
        isVariant={isVariant}
        setIsVariant={setIsVariant}
        statesData={statesListData}
        citiesData={citiesListData}
        zipCodesData={zipCodesListData}
      />
    </Fragment>
  );
}
