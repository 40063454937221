import React, { useState, useEffect } from "react";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { ButtonBase } from "components/ui/core/button";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import FormInput from "components/ui/FormContent/formInput";
import FormSelect from "components/ui/FormContent/formSelect";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import RedirectDialog from "components/ui/dialog/redirectDialog";
import ErrorDialog from "components/ui/dialog/errorDialog";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Skeleton from "@material-ui/lab/Skeleton";
import Snackbar from "@material-ui/core/Snackbar";
import { pick, set, uniqBy } from "lodash";
import { useUserView } from "contexts/userViewContext";
import {
  createUserDetail,
  updateUserDetail,
  assignUserJob,
  unassignUserJob,
  assignUserState,
  unassignUserState,
  getRoles,
  getCustomers,
  getUsersJobs,
  getStates,
} from "services/superService";

import { getSubcontractors } from "services/subcontractorService";
import useStyles from "./styles";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  role_id: 51,
  employee_number: 0,
  classification_id: 0,
  manager_id: 0,
  legacy_role_id: 5,
  crew_id: 0,
};

function useRoles() {
  return useQuery("roles", async () => {
    const { data } = await getRoles();
    return data;
  });
}

function useSubcontractors() {
  return useQuery("subcontractors", async () => {
    const { data } = await getSubcontractors();
    return data;
  });
}

function useJobs() {
  return useQuery("usersJobs", async () => {
    const { data } = await getUsersJobs();
    return data;
  });
}

function useCustomers() {
  return useQuery("usersCustomers", async () => {
    const { data } = await getCustomers();
    return data;
  });
}

function useStates() {
  return useQuery("usersStates", async () => {
    const { data } = await getStates();
    return data;
  });
}

const UserInformation = (props) => {
  const classes = useStyles();
  const [stateContext] = useUserView();
  const [roleUser, setRoleUser] = useState([]);
  const [subsUser, setSubsUser] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [data, setData] = useState(initialState);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [openError, setOpenError] = useState(false);
  const [msgError] = useState("");
  const [openRedirect, setOpenRedirect] = useState(false);
  const [msgRedirect, setMsgRedirect] = useState("");
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isSubcontractor, setIsSubcontractor] = useState(false);
  const { user, isLoading, profilePic } = stateContext ?? null;
  const queryClient = useQueryClient();

  const {
    data: dataRoles,
    isFetching: isFetchingRoles,
    isLoading: isLoadingRoles,
  } = useRoles();

  const {
    data: dataSubs,
    isFetching: isFetchingSubs,
    isLoading: isLoadingSubs,
  } = useSubcontractors();

  const {
    data: dataJobs,
    isFetching: isFetchingJobs,
    isLoading: isLoadingJobs,
  } = useJobs();

  const {
    data: dataCustomers,
    isFetching: isFetchingCustomers,
    isLoading: isLoadingCustomers,
  } = useCustomers();

  const {
    data: dataStates,
    isFetching: isFetchingStates,
    isLoading: isLoadingStates,
  } = useStates();

  const userCreate = useMutation(
    ({ data }) => {
      return createUserDetail(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        setMsgRedirect("User has been created");
        setOpenRedirect(true);
      },
      onError: (e) => {
        setMsgRedirect("Error creating user");
        setOpenRedirect(true);
      },
    }
  );

  const userUpdate = useMutation(
    ({ id, data }) => {
      return updateUserDetail(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries(["users", user.id]);
      },
    }
  );

  useEffect(() => {
    const loadUser = async () => {
      if (user !== "new") {
        setData({ ...user });
        if (Number(user.classification_id) === 15) {
          setIsSubcontractor(true);
        }
      }
      setIsLoadingUser(false);
    };
    if (!isLoading) {
      loadUser();
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (!isLoadingRoles) {
      const resultForRender = dataRoles.data.roles.map((role) => {
        return { value: role.id, label: role.name };
      });
      setRoleUser(resultForRender);
    }
  }, [dataRoles, isLoadingRoles]);

  useEffect(() => {
    if (!isLoadingSubs) {
      const resultForRenderSub = dataSubs.data.subs.map((user) => {
        return { value: user.id, label: user.first_name };
      });
      setSubsUser(resultForRenderSub);
    }
  }, [dataSubs, isLoadingSubs]);

  useEffect(() => {
    if (!isLoadingJobs) {
      const resultForRender = dataJobs.data?.jobs?.map((job) => {
        return {
          id: job.id,
          job_description: job.job_description,
          job_number: job.job_number,
        };
      });
      setJobsData(resultForRender);
    }
  }, [dataJobs, isLoadingJobs]);

  useEffect(() => {
    if (!isLoadingCustomers) {
      const resultForRender = dataCustomers.data.customers.map((customer) => {
        return { value: customer.customer_id, label: customer.name };
      });
      setCustomersData(resultForRender);
    }
  }, [dataCustomers, isLoadingCustomers]);

  useEffect(() => {
    if (!isLoadingStates) {
      const resultForRender = dataStates.data.states.map((state) => {
        return { value: state.abbreviation, label: state.name };
      });
      setStatesData(resultForRender);
    }
  }, [dataStates, isLoadingStates]);

  useEffect(() => {
    // Load customers selected
    if (user && user !== "new" && !isLoading) {
      if (user.customers.length) {
        const foundCustomers = user.customers.map((customer) => {
          return { value: customer.id, label: customer.name };
        });
        setCustomersList(uniqBy(foundCustomers, "value"));
      }
      if (user.states.length) {
        const foundStates = user.states.map((state) => {
          return { value: state.abbreviation, label: state.name };
        });
        setStatesList(uniqBy(foundStates, "value"));
      }
    }
  }, [user, isLoading]);

  useEffect(() => {
    // Load jobs selected
    if (user && user !== "new" && !isLoading) {
      if (user.jobs.length) {
        const foundJobs = user.jobs.map((job) => {
          return {
            id: job.id,
            job_number: job.job_number,
            job_description: job.job_description,
          };
        });
        setJobsList(uniqBy(foundJobs, "id"));
      }
    }
  }, [user, isLoading]);

  const handleBlur = (event) => {
    setData({ ...data, [event.name]: event.value });
  };

  const handleSave = async () => {
    const validFields = [
      "first_name",
      "last_name",
      "email",
      "role_id",
      "employee_number",
      "classification_id",
      "manager_id",
      "legacy_role_id",
      "crew_id",
    ];

    if (user === "new") {
      const dataCreate = pick(data, validFields);
      const selectedCustomers = customersList.map((customer) => customer.value);
      const selectedStates = statesList.map((state) => state.value);
      const selectedJobs = jobsList.map((job) => job.id);
      userCreate.mutate({
        data: {
          ...dataCreate,
          files: profilePic,
          customer_ids: selectedCustomers,
          job_ids: selectedJobs,
          state_ids: selectedStates,
        },
      });
    } else {
      const dataUpdate = pick(data, validFields);
      // Get list of customers to add/remove
      const existingCustomers = uniqBy(user.customers, "id").map(
        (customer) => customer.id
      );
      const selectedCustomers = customersList.map((customer) => customer.value);

      const customerListUpdate = customersList
        .filter((customer) => !existingCustomers.includes(customer.value))
        .map((customer) => {
          return customer.value;
        });
      const customerListUpdateRemove = existingCustomers
        .filter((value) => !selectedCustomers.includes(value))
        .map((value) => value);

      const existingStates = uniqBy(user.states, "abbreviation").map(
        (state) => state.abbreviation
      );
      const selectedStates = statesList.map((state) => state.value);
      const statesListUpdate = statesList
        .filter((state) => !existingStates.includes(state.value))
        .map((state) => {
          return state.value;
        });
      const statesListUpdateRemove = existingStates.filter(
        (value) => !selectedStates.includes(value)
      );
      // Get list of users to add/remove
      const existingJobs = uniqBy(user.jobs, "id").map((job) => job.id);
      const selectedJobs = jobsList.map((job) => job.id);

      const jobListUpdate = jobsList
        .filter((job) => !existingJobs.includes(job.id))
        .map((job) => {
          return job.id;
        });

      const jobListUpdateRemove = existingJobs
        .filter((value) => !selectedJobs.includes(value))
        .map((value) => value);

      userUpdate.mutate({ id: user.id, data: dataUpdate });

      if (customerListUpdate.length) {
        const dataCustomer = {
          user_ids: [user.id],
          customer_ids: customerListUpdate,
        };
        await assignUserJob(dataCustomer);
      }

      if (customerListUpdateRemove.length) {
        const dataCustomerRemove = {
          user_ids: [user.id],
          customer_ids: customerListUpdateRemove,
        };
        await unassignUserJob(dataCustomerRemove);
      }

      if (statesListUpdate.length) {
        const dataState = {
          user_ids: [user.id],
          state_ids: statesListUpdate,
        };
        await assignUserState(dataState);
      }

      if (statesListUpdateRemove.length) {
        const dataStateRemove = {
          user_ids: [user.id],
          state_ids: statesListUpdateRemove,
        };
        await unassignUserState(dataStateRemove);
      }

      if (jobListUpdate.length) {
        const dataJob = {
          user_ids: [user.id],
          job_ids: jobListUpdate,
        };
        await assignUserJob(dataJob);
      }

      if (jobListUpdateRemove.length) {
        const dataJobRemove = {
          user_ids: [user.id],
          job_ids: jobListUpdateRemove,
        };

        await unassignUserJob(dataJobRemove);
      }

      setMsgSuccess("User has been updated");
      setOpenSuccess(true);
      setJobsList([]);
      setCustomersList([]);
      setStatesList([]);
    }
  };

  const handleChangeJob = (event, value) => {
    if (value) {
      jobsList.push(value);
      setJobsList([...jobsList]);
    }
  };

  const handleChangeCustomer = (event, value) => {
    if (value) {
      customersList.push(value);
      setCustomersList([...customersList]);
    }
  };

  const handleChangeState = (event, value) => {
    if (value) {
      statesList.push(value);
      setStatesList([...statesList]);
    }
  };

  const handleChangeCrew = (event, value) => {
    setData({ ...data, crew_id: value ? value.value : 0 });
  };

  const handleCustomerDelete = (id) => {
    const updateCustomersList = customersList.filter(
      (customer) => customer.value !== id
    );
    setCustomersList(updateCustomersList);
  };

  const handleStateDelete = (id) => {
    const updateStatesList = statesList.filter((state) => state.value !== id);
    setStatesList(updateStatesList);
  };

  const handleJobDelete = (id) => {
    const updateJobsList = jobsList.filter((job) => job.id !== id);
    setJobsList(updateJobsList);
  };

  const handleCancel = () => {
    props.history.push(`/users`);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleCloseRedirect = () => {
    setOpenRedirect(false);
  };

  const handleChangeSubcontractor = (event) => {
    setIsSubcontractor(event.target.checked);
    setData({ ...data, classification_id: event.target.checked ? 15 : 0 });
  };

  if (isLoading || isLoadingUser) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      <Box className={classes.rootForm}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="first_name"
              label="First Name"
              value={data.first_name}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="last_name"
              label="Last Name"
              value={data.last_name}
              handleBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="email"
              label="Email"
              value={data.email}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <FormSelect
              gridSizes={[{ size: "md", val: 12 }]}
              name="role_id"
              label="Role"
              options={roleUser}
              value={data.role_id}
              handleBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={2} xs={12}>
            <InputLabel className={classes.inputLabel}>
              Subcontractor
            </InputLabel>
            <Switch
              checked={isSubcontractor}
              onChange={handleChangeSubcontractor}
              name="classification"
              inputProps={{ "aria-label": "secondary checkbox" }}
              className={classes.switchItem}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="employee_number"
              label={`${
                isSubcontractor ? `Sub ID (Vendor Number)` : `Employee ID`
              }`}
              value={data.employee_number}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormSelectAuto
              label={"Crew Member Parent(For Sub)"}
              gridSizes={[{ size: "md", val: 12 }]}
              options={subsUser}
              name="crew_id"
              handleChange={handleChangeCrew}
              multiple={false}
              value={
                data.crew_id
                  ? subsUser.find(
                      (sub) => Number(sub.value) === Number(data.crew_id)
                    )
                  : null
              }
            />
          </Grid>
          <Grid item md={3} xs={12}></Grid>
        </Grid>
        {[49, 34].includes(parseInt(data.role_id)) ? (
          <Grid container spacing={4}>
            <Grid item md={9} xs={12}>
              <FormSelectAuto
                label={"State"}
                gridSizes={[{ size: "md", val: 12 }]}
                options={statesData}
                name="user_sate"
                multiple={false}
                handleChange={handleChangeState}
              />
              <Box className={classes.containerSelectedElements}>
                {statesList.map((state) => (
                  <Chip
                    key={state.value}
                    size="small"
                    label={state.label}
                    onDelete={() => handleStateDelete(state.value)}
                    className={classes.chipCustomer}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={4}>
          <Grid item md={9} xs={12}>
            <Alert severity="info" className={classes.infoAlert}>
              Including/removing a customer will include/remove all customer's
              jobs.
            </Alert>
            <FormSelectAuto
              label={"Customer"}
              gridSizes={[{ size: "md", val: 12 }]}
              options={customersData}
              name="user_customer"
              multiple={false}
              handleChange={handleChangeCustomer}
            />
            <Box className={classes.containerSelectedElements}>
              {customersList.map((customer) => (
                <Chip
                  key={customer.value}
                  size="small"
                  label={customer.label}
                  onDelete={() => handleCustomerDelete(customer.value)}
                  className={classes.chipCustomer}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={9} xs={12}>
            <FormSelectJobsAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={jobsData}
              name="job_number"
              label="Jobs"
              multiple={false}
              handleChange={handleChangeJob}
            />
            <Box className={classes.containerSelectedElements}>
              {jobsList.map((job) => (
                <Chip
                  key={job.id}
                  size="small"
                  label={`${job.job_number} - ${job.job_description}`}
                  onDelete={() => handleJobDelete(job.id)}
                  className={classes.chipJob}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.boxActions}>
        <ButtonBase variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </ButtonBase>
        <ButtonBase
          color="secondary"
          onClick={handleSave}
          disabled={userUpdate.isLoading || userCreate.isLoading}
        >
          Save Changes
        </ButtonBase>
      </Box>
      {isFetchingRoles ||
      isFetchingSubs ||
      isFetchingJobs ||
      isFetchingCustomers ? (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={true}
          message="Loading..."
          className={classes.snackItem}
        />
      ) : null}

      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        title="Error"
        message={msgError}
      />
      <RedirectDialog
        open={openSuccess && !userUpdate.isLoading && !userCreate.isLoading}
        hasClose={false}
        title="Success"
        message={msgSuccess}
        redirectLabel={"Okay"}
        redirect={"/users"}
        handleClose={handleCloseSuccess}
      />
      <RedirectDialog
        open={openRedirect}
        hasClose={false}
        title={userCreate.isSuccess ? "Success" : "Error"}
        isError={!userCreate.isSuccess}
        message={msgRedirect}
        redirectLabel={"Okay"}
        redirect={"/users"}
        handleClose={handleCloseRedirect}
      />
    </>
  );
};

export default UserInformation;
