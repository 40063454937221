import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import CommentsIcon from "assets/icons/commentsIcon";
import Skeleton from "@material-ui/lab/Skeleton";
import MessageDialog from "./dialog/successDialog";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import WorkticketComment from "components/ui/Worktickets/WorkticketComment";
import {
  addWorkticketComment,
  updateWorkticketComment,
  deleteWorkticketComment,
} from "services/workticketService";
import { getWorkticketComment } from "services/workticketSurfaceService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketActivity = (props) => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useWorkticketView();
  const [tab, setTab] = useState(0);
  const [subTab] = useState("customer");
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);

  const { workticket, isLoading } = stateContext ?? null;
  const { workticketComments } = stateContext;

  const setWorkTicketComments = (comments) => {
    dispatchContext({
      type: "SET_WORKTICKET_COMMENTS",
      workticketComments: comments,
    });
    setCommentList(comments);
  };

  useEffect(() => {
    if (!isLoading) {
      setCommentList(workticketComments || []);
    }
  }, [isLoading, workticketComments, workticket.activity]);

  const handleTabButtonChange = (newValue) => {
    setTab(newValue);
  };

  const handleDeleteConfirmation = (commentId) => {
    setSelectedComment(commentId);
    setOpenConfirm(true);
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const closeMessage = () => {
    if (!isLoadingData) {
      setOpenMessage(false);
    }
  };

  const handleAddComment = async () => {
    if (comment === "") return;

    const data = { body: comment, tab: subTab };

    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      await addWorkticketComment(workticket.id, data);
      const refreshData = await getWorkticketComment(workticket.id);
      setWorkTicketComments(refreshData.data.data);

      setComment("");
      setOpenMessageContent("Comment has been added successfully.");
    } catch (e) {
      logException(e, "Cannot add comment");
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleUpdateComment = async (commentId, newBody) => {
    const data = { body: newBody, tab: subTab };

    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      await updateWorkticketComment(workticket.id, commentId, data);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].map((c) =>
          c.id === commentId ? { ...c, body: newBody } : c
        );

        setComment("");
        return updatedCommentList;
      });

      setOpenMessageContent("Comment has been updated successfully.");
    } catch (e) {
      logException(e, "Cannot update comment");
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteWorkticketComment(workticket.id, commentId);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].filter(
          (c) => c.id !== commentId
        );
        return updatedCommentList;
      });

      setOpenConfirm(false);
    } catch (e) {
      logException(e, "Cannot delete comment");
    }
  };

  const renderComments = () => {
    const comments = commentList[subTab] || [];
    return comments.map((comment) => (
      <WorkticketComment
        key={comment.id}
        id={comment.id}
        externalId={comment.external_id}
        user={{
          id: comment.user_id,
          name: comment.user_name,
          avatar: comment.profile_url,
        }}
        time={comment.video_metadata}
        date={comment.created_at}
        message={comment.body}
        fileId={comment.files_id}
        fileUrl={comment.file_url}
        fileName={comment.display_name}
        fileOwner={comment.user_name}
        fileType={comment.mime_type}
        fileSize={comment.file_size}
        fileCreateAt={comment.files_created_at}
        workticketId={workticket.id}
        workticketNumber={workticket.number}
        displayFileDetails={true}
        onUpdateComment={handleUpdateComment}
        onDeleteComment={() => handleDeleteConfirmation(comment.id)}
      />
    ));
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <Box className={classes.containerBodyActivity}>
      <Box className={classes.containerTabs}>
        {[
          {
            label: "Comments",
            count: commentList["customer"]?.length || 0,
            index: 0,
          },
        ].map((tabInfo) => (
          <Box
            key={tabInfo.label}
            className={classNames(classes.buttonTab, {
              [classes.buttonTabActive]: tab === tabInfo.index,
            })}
            onClick={() => handleTabButtonChange(tabInfo.index)}
          >
            {tabInfo.label === "Comments" && (
              <CommentsIcon
                strokeColor={tab === tabInfo.index ? "#ffffff" : "#4f98bc"}
              />
            )}
            <Typography variant="body2" style={{ paddingLeft: 2 }}>
              {`${tabInfo.label} (${tabInfo.count})`}
            </Typography>
          </Box>
        ))}
      </Box>

      {tab === 0 && (
        <Box className={classes.containerComments}>
          {renderComments()}

          <Box className={classes.containerCommentsForm}>
            <Input
              className={classes.inputNewComments}
              disableUnderline={true}
              rows={6}
              multiline={true}
              fullWidth={true}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Box className={classes.boxActions}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
                className={classNames(classes.button)}
                onClick={handleAddComment}
              >
                Add Comment
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <ConfirmDialog
        open={openConfirm}
        handleConfirm={() => handleDeleteComment(selectedComment)}
        handleClose={closeConfirm}
        message={"Are you sure you want to delete this comment?"}
        title={`Workticket ${workticket.number}`}
      />
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </Box>
  );
};

export default WorkticketActivity;
