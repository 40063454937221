import React, { Fragment, useEffect, useReducer, useState } from "react";
import Box from "@material-ui/core/Box";
import FormInput from "../../../components/ui/FormContent/formInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles from "../styles";
import { useFilterDialog } from "../context/FilterDialogContext";
import ThreeDots from "../icons/ThreeDots";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FilterModal from "pages/partnersList/dialog/partnerFiltersDialog";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import {
  filterCitiesBySelectedStates,
  filterZipCodesBySelectedCities,
} from "components/util/filterGeolocationUtils";
import * as classnames from "classnames";

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "TOUCH_FIELD":
      return { ...state, [action.field]: true };
    default:
      return state;
  }
};

// Initial state for touched fields
const initialState = {
  variant_name: false,
  // hourly_rate: false,
  // fixed_rate: false,
  // sqf_rate: false,
  states: false,
  cities: false,
  zip_codes: false,
};

export function TradeVariantForm({
  data,
  handleBlur,
  index,
  deleteVariant,
  setVariantHasError,
  failedSubmitVariant,
  citiesListData,
  statesListData,
  zipCodesListData,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [touchedFields, dispatch] = useReducer(reducer, initialState);
  const { setIsEditingVariant, variantsLocations, setVariantsLocations } =
    useFilterDialog();
  const [selectedItems, setSelectedItems] = useState({
    trades: [],
    states: [],
    cities: [],
    zipCodes: [],
  });

  const dataLists = {
    states: statesListData,
    cities: citiesListData,
    zipCodes: zipCodesListData,
  };

  // Mark unfilled fields as touched when failedSubmitVariant is true
  useEffect(() => {
    if (failedSubmitVariant) {
      const fieldsToCheck = [
        { field: "variant_name", value: data.variant_name },
        // { field: "hourly_rate", value: data.hourly_rate },
        // { field: "fixed_rate", value: data.fixed_rate },
        // { field: "sqf_rate", value: data.sqf_rate },
        { field: "states", value: selectedItems.states.length },
        { field: "cities", value: selectedItems.cities.length },
        { field: "zip_codes", value: selectedItems.zipCodes.length },
      ];

      fieldsToCheck.forEach(({ field, value }) => {
        if (!value) {
          dispatch({ type: "TOUCH_FIELD", field });
        }
      });
    }
  }, [
    failedSubmitVariant,
    data,
    selectedItems.states,
    selectedItems.cities,
    selectedItems.zipCodes,
  ]);

  useEffect(() => {
    const array = [...variantsLocations];
    array[index] = {
      ...array[index],
      variant_name: data.variant_name,
      hourly_rate: data.hourly_rate,
      fixed_rate: data.fixed_rate,
      sqf_rate: data.sqf_rate,
      states: selectedItems.states.map((item) => item.value),
      cities: selectedItems.cities.map((item) => item.value),
      zip_codes: selectedItems.zipCodes.map((item) => item.value),
    };

    // Check if at least one of the rate fields is filled
    const hasAtLeastOneRate =
      data.fixed_rate || data.hourly_rate || data.sqf_rate;

    // Required fields
    const requiredFields = ["variant_name", "zip_codes", "cities", "states"];
    const hasError =
      // !hasAtLeastOneRate ||
      requiredFields.some((field) => {
        return (
          array[index][field] === null ||
          array[index][field] === undefined ||
          array[index][field] === ""
        );
      });

    setVariantHasError(hasError);
    setVariantsLocations(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedItems.states,
    selectedItems.cities,
    selectedItems.zipCodes,
    data,
  ]);

  const handleBlurField = (event, fieldName) => {
    dispatch({ type: "TOUCH_FIELD", field: fieldName });
    if (handleBlur) handleBlur(event, index);
  };

  useEffect(() => {
    if (selectedItems.states.length > 0) {
      const assignedCitiesToSelectedStates = filterCitiesBySelectedStates(
        selectedItems.cities,
        selectedItems.states
      );

      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        cities: assignedCitiesToSelectedStates,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.states]);

  useEffect(() => {
    if (selectedItems.cities.length > 0) {
      const assignedZipCodesToSelectedCities = filterZipCodesBySelectedCities(
        selectedItems.zipCodes,
        selectedItems.cities
      );

      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        zipCodes: assignedZipCodesToSelectedCities,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.cities]);

  const handleOpenModal = (type) => {
    setFilterType(type);

    const selectedItemsForType = dataLists[type].filter((item) =>
      selectedItems[type]?.some((selected) => selected.value === item.value)
    );
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [type]: selectedItemsForType,
    }));

    setModalOpen(true);
  };

  const handleModalChange = (selected) => {
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [filterType]: selected,
    }));

    setModalOpen(false);
  };

  const handleCloseModal = () => setModalOpen(false);

  const renderSelectedValues = (items) => {
    return items.map((item) => item.label).join(", ");
  };

  return (
    <Fragment key={index}>
      <Box key={index} className={classes.variantBackground}>
        <Box
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={classes.threeDotVariantContainer}
        >
          <ThreeDots />
        </Box>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="variant_name"
          label="Variant Name *"
          value={data.variant_name}
          readOnly={false}
          error={
            touchedFields.variant_name &&
            (data.variant_name === null ||
              data.variant_name === undefined ||
              data.variant_name === "") && [
              { key: "variant_name", message: "This field is required" },
            ]
          }
          styleOverride={{ minWidth: "114px" }}
          handleBlur={(e) => handleBlurField(e, "variant_name")}
        />
        <Box className={classes.ratesFormContainer}>
          <FormInput
            gridSizes={[{ size: "md", val: 6 }]}
            name="hourly_rate"
            label="Hourly Rate"
            type="number"
            value={data.hourly_rate}
            readOnly={false}
            // error={
            //   touchedFields.hourly_rate &&
            //   !(data.fixed_rate || data.hourly_rate || data.sqf_rate) && [
            //     {
            //       key: "hourly_rate",
            //       message: "At least one rate is required",
            //     },
            //   ]
            // }
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            styleOverride={{ minWidth: "114px" }}
            handleBlur={(e) => handleBlurField(e, "hourly_rate")}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 6 }]}
            name="fixed_rate"
            label="Fixed Rate"
            value={data.fixed_rate}
            type="number"
            // error={
            //   touchedFields.fixed_rate &&
            //   !(data.fixed_rate || data.hourly_rate || data.sqf_rate) && [
            //     { key: "fixed_rate", message: "At least one rate is required" },
            //   ]
            // }
            readOnly={false}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            styleOverride={{ minWidth: "114px" }}
            handleBlur={(e) => handleBlurField(e, "fixed_rate")}
          />
        </Box>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="sqf_rate"
          label="Sq Footage Rate"
          type="number"
          value={data.sqf_rate}
          // error={
          //   touchedFields.sqf_rate &&
          //   !(data.fixed_rate || data.hourly_rate || data.sqf_rate) && [
          //     { key: "sqf_rate", message: "At least one rate is required" },
          //   ]
          // }
          readOnly={false}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          styleOverride={{ minWidth: "114px" }}
          handleBlur={(e) => handleBlurField(e, "sqf_rate")}
        />
        <>
          <Box>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel className={classes.filterLabel}>
                States Serviced*
              </FormLabel>
              <Box
                onClick={() => {
                  handleOpenModal("states");
                  setIsEditingVariant(true);
                  setFilterType("states");
                }}
                className={classes.box}
              >
                <TextField
                  required
                  fullWidth
                  id="states"
                  name="states"
                  variant="standard"
                  placeholder="Select state"
                  value={renderSelectedValues(selectedItems.states)}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    endAdornment: (
                      <IconButton>
                        <ArrowRightIcon />
                      </IconButton>
                    ),
                  }}
                  className={classnames(classes.inputFieldFilters, {
                    [classes.readOnlyInput]: selectedItems.trades.length <= 0,
                  })}
                />
              </Box>
            </FormControl>
            {touchedFields.states &&
              (selectedItems.states.length === 0 || false) && (
                <FormHelperText className={classes.formHelperText}>
                  {"This field is required"}
                </FormHelperText>
              )}
          </Box>

          <Box>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel
                className={
                  selectedItems.states.length > 0
                    ? classes.filterLabel
                    : classes.inputLabel
                }
              >
                Cities Serviced*
              </FormLabel>
              <Box
                onClick={() => {
                  if (selectedItems.states.length > 0) {
                    handleOpenModal("cities");
                    setIsEditingVariant(true);
                  }
                }}
                className={classes.box}
              >
                <TextField
                  required
                  fullWidth
                  id="cities"
                  name="cities"
                  variant="standard"
                  placeholder="Select Cities "
                  value={renderSelectedValues(selectedItems.cities)}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    endAdornment: selectedItems.states.length > 0 && (
                      <IconButton>
                        <ArrowRightIcon />
                      </IconButton>
                    ),
                  }}
                  className={classnames(classes.inputFieldFilters, {
                    [classes.readOnlyInput]: selectedItems.states.length <= 0,
                  })}
                />
              </Box>
            </FormControl>
            {touchedFields.cities &&
              (selectedItems.cities.length === 0 || false) && (
                <FormHelperText className={classes.formHelperText}>
                  {"This field is required"}
                </FormHelperText>
              )}
          </Box>
          <Box>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel
                className={
                  selectedItems.cities.length > 0
                    ? classes.filterLabel
                    : classes.inputLabel
                }
              >
                Zip Codes
              </FormLabel>

              <Box
                onClick={() => {
                  if (selectedItems.cities.length > 0) {
                    setIsEditingVariant(true);
                    setFilterType("zip_codes");
                    handleOpenModal("zipCodes");
                  }
                }}
                className={classes.box}
              >
                <TextField
                  fullWidth
                  id="zipCodes"
                  name="zipCodes"
                  variant="standard"
                  placeholder="Select Zip Codes"
                  value={renderSelectedValues(selectedItems.zipCodes)}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    endAdornment: selectedItems.cities.length > 0 && (
                      <IconButton>
                        <ArrowRightIcon />
                      </IconButton>
                    ),
                  }}
                  className={classnames(classes.inputFieldFilters, {
                    [classes.readOnlyInput]:
                      selectedItems.cities.length <= 0 &&
                      selectedItems.zipCodes.length === 0,
                  })}
                />
              </Box>
            </FormControl>
            {touchedFields.zip_codes &&
              (selectedItems.zipCodes.length === 0 || false) && (
                <FormHelperText className={classes.formHelperText}>
                  {"This field is required"}
                </FormHelperText>
              )}
          </Box>
        </>
        <FilterModal
          isOpen={modalOpen}
          onCloseModal={handleCloseModal}
          availableStates={null}
          filterType={filterType}
          optionsList={dataLists[filterType] || []}
          currentSelections={selectedItems}
          onFilterChange={handleModalChange}
        />
      </Box>
      <Menu
        className={classes.addRatingModalEndContainer}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            deleteVariant(index);
            setVariantHasError(false);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
